.btn__default__color {
  background-color: rgb(255, 172, 61);
  font-family: var(--font-family);
  color: rgb(255, 255, 255);
  text-decoration: none;
  white-space: nowrap;
  line-height: normal;
  font-weight: 500;
  font-size: 20px;
  padding: 20px 40px;
  border-radius: 8px;
  border-width: 0px;
}
.btn__default{
  background-color: #047857;
  box-shadow: 0px 5px 30px 0px #7b6ff67a;
  border: 0;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  line-height: 57px;
  overflow: hidden;
  padding: 0 42px;
  position: relative;
  transition: all .4s ease-in-out;
  z-index: 0;
}
.btn__default:hover {
  background-color: rgb(255, 172, 61);
  box-shadow: 0px 4px 20px 0px #efa23e9c;
}
.pagination__buttonsss{
  background-color: #047857;
  box-shadow: 0px 5px 30px 0px #7b6ff67a;
  border: 0;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  line-height: 57px;
  overflow: hidden;
  padding: 0 42px;
  position: relative;
  transition: all .4s ease-in-out;
  z-index: 0;
  margin-bottom: 37px;
}
.pagination__buttonsss svg {
  vertical-align: middle;
}
.pagination__buttonsss:hover {
  background-color: rgb(255, 172, 61);
  box-shadow: 0px 4px 20px 0px #efa23e9c;
}
.btn__default__two{
  background-color: #ffb255;
  box-shadow: 0px 5px 30px 0px #efa23e9c;
  border: 0;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  line-height: 57px;
  overflow: hidden;
  padding: 0 42px;
  position: relative;
  transition: all .4s ease-in-out;
  z-index: 0;
}
.btn__default__two:hover {
  background-color: #eca34a;
  box-shadow: 0px 4px 20px 0px #ffba5f73;
}
.btn__default__dels {
  background-color: #ff1504;
  box-shadow: 0px 5px 30px 0px #e5857f;
  border: 0;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  line-height: 58px;
  overflow: hidden;
  padding: 0 42px;
  position: relative;
  transition: all .4s ease-in-out;
  z-index: 0;
}
.btn__default__dels:hover {
  background-color: #ed1e0f;
}
.required {
  color: #f44336;
  font-size: 18px;
  font-weight: 600;
}
.errors{
  color: #f44336;
  font-size: 22px;
  font-weight: 600;
}
.mt__17{margin-top: 17px;}
.mt__27{margin-top: 27px;}
.mt__37{margin-top: 37px;}
.mb__17{margin-bottom: 17px;}
.mb__27{margin-bottom: 27px;}
.mb__37{margin-bottom: 37px;}
.default__desc, .default__desc p{ 
  font-size: 20px!important;
  color: var(--text-color)!important;
  line-height: 37px;
  font-weight: 400;
  font-family: var(--font-family)!important;
  background-color: transparent!important;
  word-break: break-word;
}
.default__desc p b, .default__desc p strong, .default__desc p span, .default__desc p em{ 
  font-size: 20px!important;
  line-height: 27px;
  font-family: var(--font-family)!important;
  word-break: break-word;
  background-color: transparent!important;
}
.default__desc p b, .default__desc p strong{
  font-weight: 600;
}

.default__desc h1,  .default__desc h2, .default__desc h3, .default__desc h4, .default__desc h5, .default__desc h6, .default__desc h1 strong,  .default__desc h2 strong, .default__desc h3 strong, .default__desc h4 strong, .default__desc h5 strong, .default__desc h6 strong {
  background-color: transparent!important;
  font-family: var(--font-family)!important;
  word-break: break-word;
  font-weight: 600;
  line-height: 27px;
  line-height: 37px;
} 

.Banner__sec {
  position: relative;
  padding: 194px 0 37px;
  height: 100vh;
}
.BS__videos {
  position: relative;
  padding-top: 60.25%;
  margin-bottom: 0;
}
.BS__videos iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bss {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.BS__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.BS__content h1{
  font-family: var(--font-family-two);
  color: rgb(4, 120, 87);
  text-decoration: none;
  white-space: normal;
  line-height: 92px;
  font-weight: 600;
  font-size: 90px;
  text-align: left;
  margin-bottom: 37px;
}
.BS__content h1 span{
  font-size: 137px;
  position: relative;
}
.BS__content h1 span:before {
  position: absolute;
  content: "";
  background-color: #ccf2c9;
  width: 100%;
  height: 80%;
  z-index: -1;
  top: 13px;
  left: -40px;
}
.BS__content h2{
  font-family: var(--font-family-two);
  color: rgb(4, 120, 87);
  text-decoration: none;
  white-space: normal;
  line-height: 92px;
  font-weight: 600;
  font-size: 90px;
  text-align: left;
  margin-bottom: 37px;
}
.BS__content h2 span{
  font-size: 137px;
  position: relative;
}
.BS__content h2 span:before {
  position: absolute;
  content: "";
  background-color: #ccf2c9;
  width: 100%;
  height: 80%;
  z-index: -1;
  top: 13px;
  left: -40px;
}

.BS__content p {
  font-family: var(--font-family);
  font-size: 22px;
  line-height: 37px;
  margin-bottom: 37px;
}
.BS__tops {
  background-color: #fff4e6;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  color: rgb(255, 255, 255);
  height: 417px;
  position: absolute;
  top: 0;
  width: 600px;
  right: 0;
}
.BS__bottoms{
  background-color: rgb(204, 242, 201);
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  color: rgb(255, 255, 255);
  height: 417px;
  position: absolute;
  bottom: 0;
  width: 600px;
  right: 0;
}
.BHS__cols {
  border-right: 1px solid #E6E6E6;
  padding: 0 27px 0 27px;
}
.bookhome__sec{
  position: relative;
  padding: 0px 0px 37px 0px;
}
.BHS__books {
  position: relative;
}
.BHS__books img {
  display: block;
  margin: 0 auto;
  width: 100%;
  border-radius: 15px;
  height: 294px;
  overflow: hidden;
  object-fit: cover;
  object-position: center top;
}
.search__navsnmqsss{
  display: none;
}
h6.premium__pricsss {
  color: #ff1100;
  font-size: 27px;
  font-weight: 600;
  margin-top: 10px;
}
p.premium__pricsssqqq {
  color: #ff1100;
  font-size: 27px;
  font-weight: 600;
  margin-top: 10px;
}
.BHS__books h2 {
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  text-transform: capitalize;
  font-size: 20px;
  color: black;
  font-weight: 400;
  margin: 17px 0 10px 0;
  line-height: 30px;
}
.BHS__booksprofile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 10px;
}
.BHS__booksprofile p{
  font-size: 18px;
  margin-bottom: 0px;
}
.BHS__booksprofile .admin__phosss {
  width: 37px;
  height: 37px!important;
  max-width: unset;
  margin: 0 auto;
  border-radius: 100%!important;
  background-color: #ff516b;
  object-fit: cover!important;
  object-position: center top;
}
.BHS__booksprofile .profile__image__char{
  width: 37px;
  height: 37px;
  margin: 0 auto;
  border-radius: 100%;
  background-color: #ff516b;
  display: flex;
  justify-content: center;
  align-items: center;
}
.BHS__booksprofile .profile__image__charsvg {
  font-size: 24px;
  vertical-align: middle;
  margin-top: -4px;
  font-size: 80px;
  color: #fff;
}
.titles{
  color: #047857;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  letter-spacing: 0px;
  font-family:  var(--font-family-two);
}
.bookhome__sec .titles {
  margin-bottom: 47px;
}
.bookhometrailer__sec {
  background-color: #ecfdf5;
  padding: 87px 0px 87px 0px;
}
.BHT__sec{
  position: relative;
  padding-top: 60.25%;
}
.BHT__sec3{
  margin-bottom: 60px;
}
.bookhometrailer__sec2 {
  position: relative;
  margin-top: -110px;
}
.BHT__sec iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 10px solid white;
  box-shadow: 0 4px 18px 0 rgb(194 200 213 / 30%);
  border-radius: 7px;
}
.bookhometrailer__sec2 .BHT__sec iframe{
  box-shadow: 0 4px 18px 0 rgb(204 242 201);
}
.bookhometrailer__sec2 .row {
  border-top: 1px solid #c9e7c6;
}
.bookhometrailer__sec2 .BHT__sec {
  margin-top: 27px;
}
.BHT__sec2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 17px;
  align-items: center;
}
.Interviewhome__sec {
  padding-top: 94px;
  padding-bottom: 0px;
}
.poemhome__sec {
  padding-bottom: 77px;
}
.titles2{
  color: #ffac3d;
}
.IH__icons {
  width: 77px;
  height: 77px;
  background-color: #fff4e6;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: 47px;
  color: #ffac3d;
  font-weight: 600;
  margin-bottom: 27px;
  border-radius: 10px;
  box-shadow: 1px 0px 7px 0 rgb(231 219 204);
}
.IH__qa {
  margin-bottom: 37px;
}
.IH__qa h2 {
  font-size: 24px;
  color: black;
  margin-bottom: 17px;
  line-height: 37px;
}
.IH__qa__desc {
  -webkit-line-clamp: 3!important;
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}
.IH__qa a {
  color: #ff9200;
  margin-top: 7px;
}
.BHS__books__left {
  margin-right: 30px;
  max-width: 194px;
  position: relative;
}
.BHS__books__right {
  flex: 1;
}
.BHS__cols22 {
  display: flex;
  align-items: center;
  padding: 30px;
  border-radius: 15px;
  margin-bottom: 60px;
}
.BHS__cols30{
  background-color: #fef5f5;
}
.BHS__cols31{
  background-color: #f4fbf3;
}
.BHS__BR__title {
  font-size: 22px;
  color: black;
  line-height: 34px;
  -webkit-line-clamp: 2!important;
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
}
.BHS__cols22 img {
  display: block;
  margin: 0 auto;
  width: 100%;
  border-radius: 15px;
  height: 270px;
  overflow: hidden;
  object-fit: cover;
  object-position: center top;
}
.publisherhome__sec {
  position: relative;
  margin-top: 67px;
}
.BHS__desc{
  -webkit-line-clamp: 3!important;
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  max-height: 112px;
}
.pubs__desc{
  -webkit-line-clamp: 6!important;
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  text-align: center;
  max-height: 197px;
  mask-image: linear-gradient(270deg,#000 50%,transparent);
  -webkit-mask-image: -webkit-linear-gradient(270deg,#000 50%,transparent);
}
.pubspoem__desc {
  -webkit-line-clamp: 6!important;
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  text-align: center;
  max-height: 197px;
  mask-image: linear-gradient(270deg,#000 50%,transparent);
  -webkit-mask-image: -webkit-linear-gradient(270deg,#000 50%,transparent);
}
.pubspoem__desc p {
  margin-bottom: 7px;
}
.publishers__titles {
  -webkit-line-clamp: 3!important;
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  font-size: 22px;
  color: black;
  line-height: 37px;
  margin-bottom: 10px;
  text-align: center;
}
.highlight__word{
  position: relative;
  z-index: 1;
}
.highlight__border{
  background-color: #ccf2c9;
  content: "";
  position: absolute;
  left: 0;
  bottom: 20%;
  width: calc(100% + 0.3em);
  height: 15%;
  margin-left: -0.15em;
  transform: skew(-12deg) translateX(0);
  z-index: -1;
}
.publisherhome__sec h2.titles {
  text-align: center;
  margin-bottom: 37px;
}
.publishers__books {
  background-color: #ffffff;
  padding: 37px 30px 37px;
  border-radius: 32px;
  box-shadow: 0px 8px 20px 0px rgb(18 17 39 / 10%);
}
.publishers__books img {
  max-height: 137px;
  margin: 0px auto 27px;
  object-fit: cover;
  display: block;
}
.articlehome__sec {
  position: relative;
  padding: 60px 0 60px 0;
}
.AH__sec2{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 47px;
  align-items: center;
}
.PSH__sec2{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 47px;
  align-items: center;
}
.interviewvideohome__sec {
  position: relative;
  padding: 60px 0px 0px 0px;
}
.IV__sec__right {
  padding: 27px;
  background-color: rgb(236, 253, 245);
  border-radius: 17px;
}
.IV__sec__right ul {
  list-style: none;
  padding: 0;
}
.IV__sec__right ul li {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  color: black;
  margin-bottom: 17px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.IV__sec__right ul li:hover {
  color: #047857;
}
.IV__sec__right svg {
  font-size: 23px;
  margin-right: 10px;
  vertical-align: middle;
  margin-top: 4px;
}
.IV__sec__right ul li a {
  flex: 1;
}
.IV__books {
  position: relative;
  padding-top: 60.25%;
  margin-bottom: 0;
}
.IV__books iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.breadcrumbs {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 37px;
  margin-top: 27px;
}
.breadcrumbs h1 {
  font-size: 37px;
  line-height: 1.1;
  font-weight: 600;
  font-family: var(--font-family-two);
  color: rgb(4, 120, 87);
  margin: 0;
  position: relative;
  display: inline-block;
  top: 5px;
  padding-bottom: 15px;
}
.breadcrumbs h1:before {
  content: "";
  width: 50px;
  height: 3px;
  background: rgb(4, 120, 87);
  bottom: 3px;
  position: absolute;
}
.books__sec__left {
  padding-Right: 37px;
}
.default__authors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.admin__phosss {
  width: 57px;
  height: 57px;
  margin: 0;
  max-width: 100%;
  display: block;
  border-radius: 100%!important;
  background-color: #ff516b;
  justify-content: center;
  align-items: center;
  object-fit: cover!important;
  object-position: center top;
}
.default__authors__right {
  margin-left: 17px;
}
.default__authors h2 {
  font-size: 22px;
  margin-bottom: 4px;
  color: #ff910c;
}
.default__authors__right p {
  margin-bottom: 0;
}
.default__imglist img {
  max-width: 100%;
  margin: 0 auto;
  max-height: 337px;
  height: auto;
  display: block;
  object-fit: contain;
  border-radius: 10px;
}

.default__sec__inner {
  margin-bottom: 47px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  background-color: #ffffff;
  padding: 37px 30px 37px;
  border-radius: 17px;
  box-shadow: 0px 8px 20px 0px rgb(18 17 39 / 10%);
}
.books__sec__right h1 {
  font-size: 30px;
  color: black;
  margin-bottom: 27px;
}
.books__sec__right h2.qwsqxxx {
  font-size: 30px;
  color: black;
  margin-bottom: 27px;
}
.poem__paginations .books__sec__right h1 {
  font-size: 24px;
  color: black;
  margin-bottom: 17px;
  line-height: 37px;
}
.poem__paginations .books__sec__right h2.qwsqxxx {
  font-size: 24px;
  color: black;
  margin-bottom: 17px;
  line-height: 37px;
}
.poem__paginations .default__sec__inner {
  align-items: flex-start;
  justify-content: flex-start;
  padding: 37px 30px 10px;
  border-radius: 10px;
  box-shadow: 0px 7px 10px 0px rgb(18 17 39 / 10%);
}
.publishers__books .default__authors img {
  margin: 0px auto 0px;
}
.publishers__books .default__authors {
  margin-top: 27px;
}
.articlehome__sec .publishers__titles{
  text-align: left;
}
.articlehome__sec .pubs__desc{
  text-align: left;
  -webkit-line-clamp: 7!important;
}
.articlehome__sec .publishers__books {
  background-color: #ffffff;
  padding: 37px 30px 37px;
  border-radius: 27px;
  box-shadow: 0px 8px 20px 0px rgb(18 17 39 / 10%);
}
.poemhome__sec{
  position: relative;
}
.PSH__sec3 {
  padding: 60px 70px 177px;
  background-color: #fff4e6;
  border-radius: 32px;
}
.PHs__tops {
  margin-top: -177px;
  padding: 0 60px 0 60px;
}
.PHs__tops .publishers__books {
  padding: 60px 60px 60px;
}
.Video__innerpage__inner {
  position: relative;
  padding-top: 60.25%;
  margin-bottom: 0;
}
.Video__innerpage__inner iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.VII__content h1 {
  font-size: 22px;
  color: black;
  margin-bottom: 17px;
  margin-top: 27px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}
.VII__content h2 {
  font-size: 22px;
  color: black;
  margin-bottom: 17px;
  margin-top: 27px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}
.VII__content {
  margin-bottom: 47px;
}
.VII__content .btn__default__two {
  background-color: #047857;
  box-shadow: 0px 4px 15px 0px #047857;
  border-radius: 7px;
  line-height: 54px;
  padding: 0 37px;
}
.VII__content:hover .btn__default__two {
  background-color: #ffb255;
  box-shadow: 0px 5px 30px 0px #efa23e9c;
}
.audio__section3 {
  padding: 27px 27px 27px 27px;
  margin-bottom: 48px;
  border-radius: 17px;
  box-shadow: 0px 8px 20px 0px rgb(18 17 39 / 10%);
}
.audio__section3 img {
  background: #f5f6f9;
  object-fit: contain;
  object-position: center top;
  width: 100%;
  height: 280px;
  max-width: 100%;
  padding: 17px;
  border-radius: 7px;
}
.audio__section3 h1 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 500;
  text-align: center;
  color: #064532;
  margin-top: 27px;
  margin-bottom: 17px;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  text-transform: capitalize;
}
.audio__section3 h2 {
  font-size: 22px;
  line-height: 27px;
  font-weight: 500;
  text-align: center;
  color: #064532;
  margin-top: 27px;
  margin-bottom: 17px;
  -webkit-line-clamp: 1!important;
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  text-transform: capitalize;
}
.audio__section3 h6 {
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  color: #ff3b00;
  margin-bottom: 10px;
}
.audioinner__desccc{
  -webkit-line-clamp: 4!important;
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  text-align: center;
  height: 148px;
}
.BHS__cols__space{
  margin-bottom: 37px;
}
.VII__content h1 {
  line-height: 30px;
  text-align: left;
}
.VII__content h2 {
  line-height: 30px;
  text-align: left;
}
.pubs__section3 .audio__section3 img {
  height: 157px;
}
.books__section__preview img {
  margin: 0 auto 37px;
  background-color: #fff;
  border-radius: 7px;
  max-height: 337px;
  box-shadow: -4px 24px 39px -17px rgb(0 0 0 / 60%);
  max-width: 337px;
  display: flex;
}
.books__section__preview{
  position: relative;
  padding: 37px 0px 60px 0px;
}
.book__prewsss {
  display: flex;
  align-items: flex-start;
}
.Authors__img {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #ccf2c9;
  margin-bottom: 27px;
  padding: 17px;
  background: linear-gradient(90deg,#ccf2c9 17%,#fff 60%)!important;
  border-radius: 67px;
}
.Authors__imgcontent p {
  color: #047857;
  font-size: 22px;
  font-weight: 600;
}
.Authors__img a {
  display: flex;
}
.Authors__img img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  object-position: center top;
  margin-bottom: 0;
  border-radius: 100%;
}
.Authors__imgcontent {
  display: flex;
  flex-direction: column;
  margin-left: 17px;
  align-items: flex-start;
  justify-content: center;
}
.bookpres__linsss b {
  color: #ff1100;
}
.bookpres__linsss li {
  font-size: 22px;
  cursor: pointer;
  color: #0676cf;
  margin-bottom: 17px;
  line-height: 37px;
  word-break: break-word;
}
h1.book__preview__title {
  font-size: 24px;
  color: black;
  margin-bottom: 10px;
  line-height: 37px;
}
h2.book__preview__title {
  font-size: 24px;
  color: black;
  margin-bottom: 10px;
  line-height: 37px;
}
.badge {
  margin-top: 17px;
  margin-bottom: 20px;
  color: #e18100;
  font-size: 22px;
}
ul.bookpres__linsss {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}
.boozzz2 p {
  font-size: 22px;
  margin-bottom: 27px;
}
.Authors__img .Authors__imgcontent p {
  font-size: 22px;
  margin-bottom: 0px;
}
.Authors__img .Authors__imgcontent span {
  color: #e28300;
  margin-top: 7px;
}
.boozzz2 p b{
  font-weight: 600;
  color: black;
}
.title__bestseller h1.sub__headingsss2s {
  color: #047857;
  font-family: var(--font-family-two);
  font-weight: 700;
  margin-bottom: 17px;
  font-size: 27px;
}
.title__bestseller h2.sub__headingsss2s {
  color: #047857;
  font-family: var(--font-family-two);
  font-weight: 700;
  margin-bottom: 17px;
  font-size: 27px;
}
h6.bookpres__pricsss {
  font-size: 24px;
  color: #ff1100;
  font-weight: 800;
  margin-bottom: 17px;
}
.mypages__authors {
  padding: 67px 0 67px;
  position: relative;
  background-color: #ecfdf5;
}
.mypages__authors__rows {
  background-color: white;
  padding: 27px;
  display: flex;
  align-items: center;
}
.mypages__authors h2 {
  font-size: 37px;
  margin-top: 7px;
  text-transform: capitalize;
  text-align: left;
  font-weight: 700;
  font-family: var(--font-family-two);
  color: rgb(4, 120, 87);
  margin-bottom: 27px;
}
.admin__phosss2{
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 100%!important;
  background-color: #ff516b;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover!important;
  object-position: center top;
}
.mypages__desccc {
  margin-top: 10px;
  max-height: 194px;
  overflow: hidden;
  mask-image: linear-gradient(270deg,#000 50%,transparent);
  -webkit-mask-image: -webkit-linear-gradient(270deg,#000 37%,transparent);
}
.mypages__desctwo {
  height: auto;
  max-height: unset;
  mask-image: linear-gradient(270deg,#000 50%,#000);
  -webkit-mask-image: -webkit-linear-gradient(270deg,#000 50%,#000);
}
.mypages__more {
  margin-top: 0;
  margin-bottom: 1rem;
  cursor: pointer;
  text-decoration: underline;
  font-size: 20px;
  color: #ff9200;
}
.mypages__listsss {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  justify-content: flex-start;
  margin-top: 30px;
  list-style: none;
}
.mypages__listsss li {
  font-size: 20px;
  margin-right: 27px;
  cursor: pointer;
  color: black;
}
.mypages__listsss li:hover {
  color: #2196f3;
}
.mypages__listsss span {
  color: #2196f3;
  font-weight: 500;
}
ul.mypages__menuslists {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 37px;
  margin-bottom: 47px;
}
.mypages__listsss li {
  font-size: 20px;
  margin-right: 27px;
  cursor: pointer;
  color: black;
}
.mypages__listsss span {
  color: #2196f3;
  font-weight: 500;
}
ul.mypages__menuslists li {
  padding: 17px 27px;
  background-color: #ecfdf5;
  margin-right: 17px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 7px;
  color: black;
  box-shadow: 0px 2px 3px #e2e2e2;
}
.mypageactivesss {
  color: #047857;
  border: 1px solid #047857;
}
.pubs__previews img{
  box-shadow: none;
}
.inersss__descs {
  -webkit-line-clamp: 7!important;
  -webkit-box-orient: vertical;
  display: -webkit-box!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  margin-top: 27px;
  margin-bottom: 27px;
  max-height: 290px;
  mask-image: linear-gradient(270deg,#000 50%,transparent);
  -webkit-mask-image: -webkit-linear-gradient(270deg,#000 50%,transparent);
}
.books__section__preview .article__previews2 img {
  margin: 0 0 30px 0;
  max-height: 377px;
  max-width: 100%;
}
.article__previews h1.book__preview__title {
  font-size: 27px;
}
.poems__previews h1.book__preview__title {
  font-size: 32px;
}
.article__previews h2.book__preview__title {
  font-size: 27px;
}
.poems__previews h2.book__preview__title {
  font-size: 32px;
}
.ga__sss {
  height: 123px;
  overflow: hidden!important;
  mask-image: linear-gradient(270deg,#000 50%,transparent);
  -webkit-mask-image: -webkit-linear-gradient(270deg,#000 50%,transparent);
}
.authors__section__list {
  padding: 27px 27px 27px 27px;
  margin-bottom: 48px;
  border-radius: 17px;
  box-shadow: 0px 8px 20px 0px rgb(18 17 39 / 10%);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
img.seller__img {
  width:147px;
  height: 147px;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 17px;
}
h4.authors__lists__names2 {
  color: black;
  font-size: 22px;
  text-align: center;
}
.authors__lists__descsss {
  max-height: 167px;
  min-height: 77px;
  overflow: hidden;
  mask-image: linear-gradient(270deg,#000 50%,transparent);
  -webkit-mask-image: -webkit-linear-gradient(270deg,#000 50%,transparent);
  text-align: center;
}
.profileimages__authors {
  background-color: #fff4e6;
  position: relative;
  height: 147px;
  width: 147px;
  border-radius: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.profileimages__authors span {
  font-size: 90px;
  text-transform: capitalize;
  vertical-align: middle;
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  font-family: var(--font-family-two);
  color: #047857;
  font-weight: 400;
}
p.authors__lists__names3 {
  text-align: left;
  color: #696969;
  margin-bottom: 17px;
  font-size: 18px;
}
.authors__section__preview h2 {
  margin-bottom: 10px;
}
.authors__section__preview svg {
  font-size: 22px;
  vertical-align: middle;
  margin-right: 7px;
  margin-top: -3px;
}
.authors__section__preview .mypages__authors {
  padding: 30px 20px 30px 20px;
  position: relative;
  background-color: #ecfdf5;
  margin-bottom: 37px;
}
.AP__previews__img{
  border-radius: 15px;
  margin-bottom: 30px;
  width: 300px;
  height: 300px;
  object-fit: cover;
}
.QAA__previews {
  border: 1px solid #d9d9d9;
  padding: 37px;
  margin-bottom: 27px;
  border-radius: 10px;
}
.QAA__previews__questions {
  color: black;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
}
.QAA__previews__date{
  font-size: 18px!important;
}
.QAA__previews__date svg {
  vertical-align: middle;
  margin-top: -2px;
}
.Authors__img2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 27px;
}
.Authors__img2 a {
  display: flex;
}
.Authors__img2 img {
  width: 237px;
  height: 237px;
  object-fit: cover;
  object-position: center top;
  margin-bottom: 0;
  border-radius: 7px;
}
.Authors__imgcontent2 {
  display: flex;
  flex-direction: column;
  margin-left: 27px;
  align-items: flex-start;
  justify-content: center;
}
.Authors__imgcontent2 h4 {
  font-size: 32px;
  color: #047857;
  font-weight: 600;
}
.Authors__imgcontent2 span {
  color: #e28300;
  margin-top: 7px;
  font-size: 22px;
}
.generated__previews{
  border: 1px solid #d9d9d9;
  padding: 37px;
  margin-bottom: 27px;
  border-radius: 10px;
}
.qustion__previews{
  color: black;
  font-size: 30px;
  margin-bottom: 17px;
  font-weight: 700;
}
.interviews__paginations .default__sec__inner {
  position: relative;
  display: block;
}
.interviews__paginations .IV__books {
  position: relative;
  padding-top: 37.25%;
  margin-bottom: 37px;
  width: 60%;
}
.interviews__paginations .books__sec__right h1 {
  font-size: 27px;
  color: black;
  margin-bottom: 27px;
}
.interviews__paginations .books__sec__right h2.qwsqxxx {
  font-size: 27px;
  color: black;
  margin-bottom: 27px;
}
.interviews__paginations .books__sec__right h2 {
  font-size: 27px;
  color: black;
  margin-bottom: 27px;
}
.interviews__paginations .IH__qa__desc {
  -webkit-line-clamp: 7!important;
  margin-bottom: 37px;
}
.interviews__paginations .btn__default__two{
  margin-bottom: 0px;
}
.preview__desc__books{
  border: 1px solid #d9d9d9;
  padding: 37px;
  margin-bottom: 27px;
  border-radius: 10px;
}
.preview__desc__books {
  border: 1px solid #d9d9d9;
  padding: 30px 37px 37px 37px;
  margin-bottom: 27px;
  border-radius: 10px;
  margin-top: 37px;
}
.preview__desc__books .title__bestseller h1.sub__headingsss2s {
  margin-bottom: 27px;
  font-size: 30px;
}
.preview__desc__books .title__bestseller h2.sub__headingsss2s {
  margin-bottom: 27px;
  font-size: 30px;
}
.book__prewsss.book__prewsss__publishersss {
  align-items: center;
}
.preview__desc__articles {
  border: 1px solid #d9d9d9;
  padding: 47px;
  margin-bottom: 27px;
  border-radius: 10px;
  margin-top: 10px;
}
.breadcrumbs__interviews {
  position: relative;
  margin-top: 27px;
}
.breadcrumbs__interviews ul {
  padding: 0;
  list-style: none;
}
.breadcrumbs__interviews ul li {
  display: inline-block;
}
.breadcrumbs__interviews ul li svg {
  vertical-align: middle;
  margin: 0 7px 0 4px;
}
.breadcrumbs__interviews ul li a {
  font-size: 20px;
}
.breadcrumbs__interviews ul li span {
  color: #047857;
  font-weight: 600;
}
.mypages__authors .profile__image__char{
  width: 300px;
  height: 300px;
  background-color: #ecfdf5;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
}
.mypages__authors .profile__image__char svg {
  font-size: 137px;
  color: #04785796;
}
.IH__qa h2 a{
  color: black;
}
.newinterview__col3{
  margin-top: 37px;
}
.IVVHS h2 {
  font-size: 24px;
  color: black;
  margin-bottom: 17px;
  line-height: 37px;
  margin-top: 17px;
  text-align: center;
}
.IVVHS2 {
  margin-bottom: 24px;
  border: 1px solid lavender;
  padding: 22px 17px 27px 17px;
  border-radius: 10px;
  background-color: #fff4e6;
}
.IVVHS2 .IV__books {
  padding-top: 77.25%;
}
iframe.docx {
  height: 240px;
}
.IVVHS2 button.btn__default__color {
  margin: 0 auto;
  display: block;
}
.IVVHS .cols {
  display: flex;
}
.profileimagesss {
  align-items: center;
  background-color: #ecfdf5;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 137px;
  height: 137px;
}
.profileimagesss svg {
  color: #04785796;
  font-size: 60px;
}
iframe.docx2 {
  height: 577px;
  max-width: 67%;
}
iframe.docx3 {
  height: 437px;
  max-width: 67%;
  display: block;
  margin-bottom: 37px;
}
.PI__imgs {
  align-items: center;
  background-color: #ecfdf5;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60px;
  height: 60px;
}
.PI__imgs__icons svg {
  color: #04785796;
  font-size: 30px;
}
p.AU__name {
  font-size: 20px;
  color: #ed9521;
}
.PIC__home {
  align-items: center;
  background-color: #ecfdf5;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  height: 64px;
  justify-content: center;
  width: 64px;
}
.PIC__home svg {
  color: #047857;
  font-size: 37px;
}
p.success__footersss {
  color: #ed9521;
}
p.errors__footersss {
  color: #ff0000;
}
span.colsss {
  color: #2196f3;
}
.divLoaderwww {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  background: #ffffff;
}
.contsqsqsqsss .form__control {padding-left: 17px!important;}
.breadcrumbs__addsss {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.BS__catsss__content {
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 37px;
  margin-bottom: 37px;
  margin-right: 20px;
}
.BS__catsss__content3 {
  font-family: var(--font-family);
  font-size: 20px;
  line-height: 37px;
  margin-bottom: 17px;
  margin-right: 20px;
}
.BS__catsss__content2 {
  text-align: center;
}
.BS__catsss__content span, .BS__catsss__content3 span, .BS__catsss__content4 span, .BS__catsss__content5 span{
  color: rgb(4, 120, 87);
  font-weight: bold;
}
.catsss__s1__titles {
  color: #047857;
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
  letter-spacing: 0px;
  margin-bottom: 17px;
  font-family: var(--font-family-two);
}
.mqsosss {
  padding-top: 17px;
}
.BS__catsss__content4 {
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 30px;
  margin-top: 27px;
  margin-right: 20px;
  border-left: 4px solid #ccf2c9;
  padding: 10px 10px 10px 20px;
  background: #ecfdf56e;
  box-shadow: 2px 3px 11px #e3f2eb;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 7px;
}
.BS__catsss__content5 {
  font-family: var(--font-family);
  font-size: 18px;
  line-height: 30px;
  margin-top: 27px;
  margin-right: 20px;
  border-left: 4px solid #fbebd6;
  padding: 10px 10px 10px 20px;
  background: #fff4e687;
  box-shadow: 2px 3px 11px #e9dac7ad;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 7px;
}
.bcc__top{
  margin-top: 37px;
}
.bcc__top2 {
  margin-top: 67px;
  margin-bottom: 0px!important;
}

.botts__1{
  margin-bottom: 20px;
}
.mqsawwww{
  font-size: 1rem;
  margin-bottom: 7px;
}

@media (max-width: 767px){
  .breadcrumbs__addsss {flex-direction: column;}
  .breadcrumbs__addsss .btn__default__color {font-weight: 400;font-size: 20px;padding: 12px 17px;margin-top: 17px;margin-bottom: 17px;}
  .breadcrumbs__interviews ul {padding-right: calc(var(--bs-gutter-x) * .5);padding-left: calc(var(--bs-gutter-x) * .5);}
  .btn__default {font-size: 20px;}
  .BS__tops {display: none;}
  .BS__bottoms {display: none;}
  .BHT__sec3 {margin-bottom: 37px;}
  .BHS__booksprofile {justify-content: center;}
  .BHS__BR__title {text-align: center;}
  .BHS__books h2 {text-align: center;}
  h6.premium__pricsss {text-align: center;}
  p.premium__pricsssqqq {text-align: center;}
  .bookhome__sec {padding: 17px 0px 7px 0px;}
  .Interviewhome__sec h2.titles {margin-bottom: 37px;}
  .Banner__sec {padding: 47px 0 37px;height: auto;}
  .BS__content h2 {line-height: 70px;font-size: 57px;}
  .BS__content h2 span {font-size: 117px;}
  .BS__content h1 {line-height: 70px;font-size: 57px;}
  .BS__content h1 span {font-size: 117px;}
  .BS__videos {margin-top: 27px;}
  .Interviewhome__sec {padding-top: 27px;padding-bottom: 0px;}
  .BHS__cols22 {align-items: center;flex-direction: column;flex-wrap: wrap;padding: 27px;margin-bottom: 37px;}
  .BHS__books__left {margin-right: 0;margin-bottom: 17px;}
  .BHS__books__right {display: flex;flex-direction: column;flex-wrap: wrap;align-items: center;}
  .default__desc.BHS__desc {text-align: center;}
  .bookhometrailer__sec {padding: 47px 0px 87px 0px;}
  .articlehome__sec {padding: 0px 0 60px 0;}
  .PSH__sec3 {padding: 60px 7px 160px;}
  .PHs__tops {padding: 0;}
  .PHs__tops .publishers__books {padding: 37px 27px 37px;margin-bottom: 47px;}
  .default__sec__inner {padding: 27px 17px 27px;}
  iframe.docx3 {max-width: 100%;}
  .interviews__paginations .IV__books {padding-top: 70.25%;width: 100%;}
  .books__sec__left {padding-Right: 0;margin-bottom: 27px;}
  .books__sec__right {display: flex;flex-direction: column;flex-wrap: wrap;align-items: center;}
  .default__desc.inersss__descs {text-align: center;}
  .book__prewsss {align-items: center;flex-direction: column;flex-wrap: wrap;}
  .preview__desc__books {padding: 27px 17px 37px 17px;margin-bottom: 0px;margin-top: 17px;}
  .boozzz2 p {font-size: 20px;margin-bottom: 17px;line-height: 30px;}
  iframe.docx2 {height: 477px;max-width: 100%;}
  .QAA__previews {padding: 17px;}
  .generated__previews {padding: 27px;}
  .preview__desc__articles {padding: 17px;}
  .article__previews .book__prewsss {align-content: flex-start;}
  .mypages__authors {padding: 47px 0 47px;}
  .mypages__authors .profile__image__char {width: 137px;height: 137px;margin: 0 auto;}
  .mypages__authors .profile__image__char svg {font-size: 60px;}
  ul.mypages__menuslists li {padding: 10px 17px;margin-right: 10px;margin-bottom: 10px;font-weight: 400;}
  .mypages .breadcrumbs {margin-top: 0;}
  .publisherhome__sec {margin-top: 47px;}
  .publishers__books {padding: 27px 17px 27px;margin-bottom: 37px;}
  .interviewvideohome__sec {padding: 37px 0px 47px 0px;}
  .AH__sec2 {margin-bottom: 27px;}
  .poemhome__sec {padding-bottom: 27px;}
  .Authors__img2 a {flex-direction: column;align-items: center;}
  .Authors__img2 img {margin-bottom: 37px;}
  .profileimagesss {margin-bottom: 37px;}
  .Authors__img2 {flex-direction: column;flex-wrap: wrap;margin-bottom: 37px;}
  .Authors__imgcontent2 {flex-direction: column;margin-left: 0;align-items: center;justify-content: center;}
  .Authors__imgcontent2 h4 {text-align: center;}
  .books__section__preview {padding: 37px 0px 37px 0px;}
  .bookpres__linsss li {word-break: break-all;}
  .authors__section__preview .mypages__authors {padding: 17px 7px 17px 7px;}
  .mypages__authors__rows {padding: 17px 7px 17px 7px;}
  .authors__section__preview h2 {text-align: center;}
  p.authors__lists__names3 {text-align: center;}
  .mypages__authors .container {padding-right: 17px;padding-left: 17px;}
  .mypages__authors .btn__default {line-height: 47px;padding: 0 17px;margin: 0 auto;display: block;}
  .AP__previews__img {height: 187px;margin: 0 auto 30px;display: block;}
  .mypages__authors h2 {font-size: 30px;}
  .mypages__authors__rows h2 {text-align: center;}
  .mypages__desccc {text-align: center;}
  .mypages__more {text-align: center;}
  .interviews__paginations .books__sec__right h1 {margin-bottom: 20px;line-height: 33px;}
  .interviews__paginations .books__sec__right h2.qwsqxxx {margin-bottom: 20px;line-height: 33px;}
  .interviews__paginations .books__sec__right h2 {margin-bottom: 20px;line-height: 33px;}
  .breadcrumbs h1 {font-size: 30px;}
  .mypages .row {justify-content: center;}
  .books__sec__right h1 {font-size: 27px;text-align: center;}
  .books__sec__right h2.qwsqxxx {font-size: 27px;text-align: center;}


}



@media(min-width:768px) and (max-width:991px){
  .Banner__sec {padding: 160px 0 37px;height: auto;}
  .BS__tops {height: 517px;width: 300px;z-index: -7;}
  .BS__bottoms {height: 587px;width: 300px;z-index: -7;}
  .BS__content h2 {font-size: 67px;}
  .BS__content h1 {font-size: 67px;}
  .Banner__sec .BS__videos {margin-top: 37px;}
  .Interviewhome__sec {padding-top: 60px;}
  .Banner__sec h2.titles {margin-bottom: 37px;}
  .bookhome__sec {padding: 27px 0px 7px 0px;}
  .BHS__books img {object-fit: contain;}
  .BHS__books h2 {font-size: 22px;text-align: center;}
  .BHS__booksprofile {align-items: center;justify-content: center;}
  h6.premium__pricsss {text-align: center;}
  p.premium__pricsssqqq {text-align: center;}
  .bookhometrailer__sec {padding: 60px 0px 87px 0px;}
  .publishers__books {margin-bottom: 37px;}
  .interviewvideohome__sec {padding: 17px 0px 0px 0px;}
  .AH__sec2 {margin-bottom: 0px;}
  .IVVHS2 {width: 100%;}
  .IVVHS2 .IV__books {padding-top: 60.25%;}
  .poemhome__sec {padding-bottom: 47px;}
  .BHS__books__right h6.premium__pricsss {text-align: left;}
  .BHS__books__right p.premium__pricsssqqq {text-align: left;}
  .boozzz2 {margin-left: 17px;}
  .books__section__preview img {max-width: 97%;}
  .poem__paginations .default__sec__inner {flex-direction: column;}

}

@media(min-width:992px) and (max-width:1199px){
  .BS__content h2 {font-size: 74px;}
  .BS__content h1 {font-size: 74px;}
  .BS__tops {height: 417px;width: 400px;z-index: -7;}
  .BS__bottoms {height: 417px;width: 400px;z-index: -7;}
  .Banner__sec {padding: 167px 0 37px;height: auto;}
  .bookhome__sec {padding: 37px 0px 60px 0px;}
  .BHS__books img {height: 197px;}
  .bookhometrailer__sec {padding: 60px 0px 87px 0px;}
  .poem__paginations .default__sec__inner {flex-direction: column;}

}

@media(min-width:1200px) and (max-width:1349px){
  .Banner__sec {height: auto;}
}
@media(min-width:1200px) and (max-width:1399px){
  .BS__bottoms { height: 317px;}
}

.mtt6 {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}